import Seo from 'src/components/seo/Seo'
import ServicesPage from 'src/components/servicos/ServicesPage'

function Servicos() {
  return (
    <>
      <Seo title="Serviços | Decathlon" />
      <ServicesPage />
    </>
  )
}

export default Servicos
